import React, { useEffect } from "react";

import { useBreakpointValue } from "@chakra-ui/react";
import { useUser } from "hooks";
import { Paths } from "paths";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  COUNTRY_OPTIONS,
  Mandate,
  useDeletePaymentMutation,
  useGetBillingAddressesQuery,
  useGetValidPaymentQuery,
  useLazyGetPaymentsQuery,
} from "store/api";
import { err } from "store/errors";
import {
  AddressInformation,
  BodyText,
  Box,
  Button,
  CreditCardDetails,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  TitleH1Os,
} from "ui";
import { utils } from "utils";
import CardVerificationFailed from "views/commons/CardVerificationFailed";

const Profile: React.FC = () => {
  const { data: billingAddress, isLoading } = useGetBillingAddressesQuery();
  const [deletePaymentMethod] = useDeletePaymentMutation();
  const { data: validPaymentData } = useGetValidPaymentQuery();
  const [getPayments] = useLazyGetPaymentsQuery();
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const user = useUser();
  const variant = useBreakpointValue({ sm: "vertical", md: "horizontal" });
  const navigate = useNavigate();
  const verifyCardMode = !!params.get("verify-card");
  const [verifyCardFailed, setVerifyCardFailed] = React.useState(false);

  useEffect(() => {
    const savedMandate = localStorage.getItem("mandate");

    const checkStatus = async () => {
      try {
        const parsedMandate = savedMandate && JSON.parse(savedMandate);
        const mandates = await getPayments().unwrap();
        const paymentToVerify = mandates.find((mandate: Mandate) => mandate.id === parsedMandate?.mandateId);

        if (paymentToVerify?.status === "valid") {
          const oldPaymentId = mandates.find((mandate: Mandate) => mandate.id !== paymentToVerify.id)?.id;
          if (oldPaymentId) {
            await deletePaymentMethod({ id: oldPaymentId });
          }
          navigate(Paths.Profile, { replace: true });
        } else {
          if (paymentToVerify) {
            await deletePaymentMethod({ id: paymentToVerify.id });
          }
          setVerifyCardFailed(true);
        }
      } catch (error) {
        err.handler(error);
      } finally {
        localStorage.removeItem("mandate");
      }
    };

    if (verifyCardMode) {
      checkStatus();
    }
  }, [deletePaymentMethod, getPayments, navigate, verifyCardMode]);

  const goToDeleteAccount = () => {
    navigate(Paths.SendDeleteAccountInstruction, { replace: true });
  };
  const goToChangeEmail = () => navigate(Paths.ChangeEmail);
  const goToChangePassword = () => navigate(Paths.ChangePassword);
  const goToAddBillingAddress = () => navigate(Paths.AddBillingAddress);
  const goToBillingAddressForm = () => navigate(Paths.EditBillingAddress);

  const goToChangeCard = () => navigate(Paths.ChangeCard);
  const goToProfile = () => navigate(Paths.Profile);

  return (
    <Box w="full">
      <TitleH1Os subtitle={t("label_action_request_personal_information_instruction")}>
        {t("label_noun_personal_information")}
      </TitleH1Os>
      <FormControl variant={variant} pb={["l", "xl"]}>
        <FormLabel htmlFor="email">{t("label_noun_e-mail")}</FormLabel>
        <Input id="email" value={user.email} maxW={["full", "full", 372]} isReadOnly isDisabled />
      </FormControl>
      <Flex direction={["column", "row"]}>
        <Stack direction={["column", "row"]} spacing={["l", "m"]}>
          <Button variant="secondary" onClick={goToChangeEmail}>
            {t("label_action_request_change_e-mail")}
          </Button>
          <Button variant="secondary" onClick={goToChangePassword}>
            {t("label_action_request_change_password")}
          </Button>
        </Stack>
        {!isLoading && !billingAddress && (
          <Button onClick={goToAddBillingAddress} mt={["l", 0]} ml={["0", "auto"]}>
            {t("label_action_request_add_billing_address")}
          </Button>
        )}
      </Flex>
      {billingAddress && (
        <Box mt={[12, "72px"]} pr={[0, "72px"]}>
          <TitleH1Os subtitle={t("label_noun_current_billing_address")}>
            {t("label_noun_your_billing_address")}
          </TitleH1Os>
          <Divider variant="dark" />
          <AddressInformation
            action={{ label: t("label_action_request_edit_address"), onClick: goToBillingAddressForm }}
          >
            <BodyText>{billingAddress.primaryAddressLine}</BodyText>
            <BodyText>{`${billingAddress.zipCode} ${billingAddress.city}`}</BodyText>
            <BodyText>{t(COUNTRY_OPTIONS[billingAddress.country])}</BodyText>
            {billingAddress.phone && <BodyText>{`${billingAddress.dialCode} ${billingAddress.phone}`}</BodyText>}
          </AddressInformation>
          <Divider variant="dark" />
        </Box>
      )}
      {!verifyCardMode && validPaymentData && (
        <>
          <TitleH1Os pt={["m", "l"]} subtitle={t("prompt_this_card_will_be_used")}>
            {t("label_noun_your_card")}
          </TitleH1Os>
          <Divider variant="dark" />
          <CreditCardDetails
            numberLabel={t("label_noun_card_number")}
            number={`**** **** **** ${validPaymentData.number}`}
            holderLabel={t("label_noun_cardholder")}
            holder={validPaymentData.holder}
            expDateLabel={t("label_other_exp_date")}
            expDate={utils.helpers.formatDateToString(new Date(validPaymentData.expiryDate), "MM/yy")}
            logo="Visa"
            action={{ label: t("label_action_request_change_card"), onClick: goToChangeCard }}
            py="s"
          />
          <Divider variant="dark" />
        </>
      )}
      {verifyCardMode && verifyCardFailed && (
        <CardVerificationFailed onRetry={goToChangeCard} onCancel={validPaymentData && goToProfile} />
      )}
      <Box textAlign={["center", "left"]} mt="xl">
        <Button variant="link" onClick={goToDeleteAccount}>
          {t("label_action_request_delete_account")}
        </Button>
      </Box>
    </Box>
  );
};

export default Profile;
